import React, { useState, useEffect } from 'react';
import {
    Box,
    Grid,
    Paper,
    Typography,
    Card,
    CardContent,
    List,
    ListItem,
    ListItemText,
    Chip,
    Avatar,
} from '@mui/material';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    PieChart,
    Pie,
    Cell,
} from 'recharts';
import {
    Warning as WarningIcon,
    CheckCircle as CheckCircleIcon,
    Error as ErrorIcon,
    AccessTime as AccessTimeIcon,
} from '@mui/icons-material';
import { getIncidents, getIncidentStatistics } from '../../services/api';
import { Incident } from '../../types';
import LoadingSpinner from '../Layout/LoadingSpinner';

const COLORS = ['#FF4842', '#FFC107', '#00AB55', '#1890FF'];

const Dashboard: React.FC = () => {
    const [incidents, setIncidents] = useState<Incident[]>([]);
    const [statistics, setStatistics] = useState<any>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const [incidentsData, statsData] = await Promise.all([
                getIncidents(),
                getIncidentStatistics()
            ]);
            setIncidents(incidentsData.results); // Access the results from the paginated response
            setStatistics(statsData);
        } catch (error) {
            console.error('Error fetching dashboard data:', error);
        } finally {
            setLoading(false);
        }
    };

    const getStatusColor = (status: string) => {
        switch (status) {
            case 'CLOSED':
            case 'RESOLVED':
                return '#00AB55';
            case 'MITIGATED':
            case 'DIAGNOSED':
                return '#1890FF';
            case 'ASSIGNED':
            case 'ACKNOWLEDGED':
                return '#FFC107';
            default:
                return '#FF4842';
        }
    };

    const getPriorityIcon = (priority: string) => {
        switch (priority) {
            case 'CRITICAL':
                return <ErrorIcon color="error" />;
            case 'HIGH':
                return <WarningIcon sx={{ color: 'warning.main' }} />;
            case 'MEDIUM':
                return <AccessTimeIcon color="info" />;
            default:
                return <CheckCircleIcon color="success" />;
        }
    };

    if (loading) {
        return <LoadingSpinner />;
    }

    const recentIncidents = incidents.slice(0, 5);
    const priorityData = Object.entries(statistics?.by_priority || {}).map(([name, value]) => ({
        name,
        value,
    }));

    const statusData = Object.entries(statistics?.by_status || {}).map(([name, value]) => ({
        name,
        value,
    }));

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom sx={{ color: '#fff', mb: 4 }}>
                Dashboard
            </Typography>

            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={3}>
                    <Card sx={{ 
                        height: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0.05)',
                        backdropFilter: 'blur(10px)',
                        border: '1px solid rgba(255, 255, 255, 0.1)',
                    }}>
                        <CardContent>
                            <Typography sx={{ color: 'rgba(255, 255, 255, 0.6)' }} gutterBottom>
                                Total Incidents
                            </Typography>
                            <Typography variant="h4" sx={{ color: '#fff' }}>
                                {statistics?.total_incidents || 0}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Card sx={{ 
                        height: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0.05)',
                        backdropFilter: 'blur(10px)',
                        border: '1px solid rgba(255, 255, 255, 0.1)',
                    }}>
                        <CardContent>
                            <Typography sx={{ color: 'rgba(255, 255, 255, 0.6)' }} gutterBottom>
                                Open Incidents
                            </Typography>
                            <Typography variant="h4" sx={{ color: '#FF4842' }}>
                                {statistics?.open_incidents || 0}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Card sx={{ 
                        height: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0.05)',
                        backdropFilter: 'blur(10px)',
                        border: '1px solid rgba(255, 255, 255, 0.1)',
                    }}>
                        <CardContent>
                            <Typography sx={{ color: 'rgba(255, 255, 255, 0.6)' }} gutterBottom>
                                Critical Incidents
                            </Typography>
                            <Typography variant="h4" sx={{ color: '#FFC107' }}>
                                {statistics?.critical_incidents || 0}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Card sx={{ 
                        height: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0.05)',
                        backdropFilter: 'blur(10px)',
                        border: '1px solid rgba(255, 255, 255, 0.1)',
                    }}>
                        <CardContent>
                            <Typography sx={{ color: 'rgba(255, 255, 255, 0.6)' }} gutterBottom>
                                Resolution Rate
                            </Typography>
                            <Typography variant="h4" sx={{ color: '#00AB55' }}>
                                {statistics?.total_incidents
                                    ? Math.round(
                                          ((statistics.total_incidents - statistics.open_incidents) /
                                              statistics.total_incidents) *
                                              100
                                      )
                                    : 0}
                                %
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Paper sx={{ 
                        p: 3,
                        height: '400px',
                        backgroundColor: 'rgba(255, 255, 255, 0.05)',
                        backdropFilter: 'blur(10px)',
                        border: '1px solid rgba(255, 255, 255, 0.1)',
                    }}>
                        <Typography variant="h6" gutterBottom sx={{ color: '#fff' }}>
                            Incidents by Priority
                        </Typography>
                        <Box sx={{ width: '100%', height: 'calc(100% - 32px)' }}>
                            <ResponsiveContainer>
                                <PieChart>
                                    <Pie
                                        data={priorityData}
                                        dataKey="value"
                                        nameKey="name"
                                        cx="50%"
                                        cy="50%"
                                        outerRadius={120}
                                        label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                                    >
                                        {priorityData.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                    <Tooltip 
                                        contentStyle={{ 
                                            backgroundColor: '#1A1A1A',
                                            border: '1px solid rgba(255, 255, 255, 0.1)',
                                        }}
                                        labelStyle={{
                                            color: '#FFFFFF',  // text color of the label
                                        }}
                                        itemStyle={{
                                            color: '#FFFFFF',  // text color for the individual items inside the tooltip
                                        }}
                                    />
                                    <Legend />
                                </PieChart>
                            </ResponsiveContainer>
                        </Box>
                    </Paper>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Paper sx={{ 
                        p: 3,
                        height: '400px',
                        backgroundColor: 'rgba(255, 255, 255, 0.05)',
                        backdropFilter: 'blur(10px)',
                        border: '1px solid rgba(255, 255, 255, 0.1)',
                    }}>
                        <Typography variant="h6" gutterBottom sx={{ color: '#fff' }}>
                            Incidents by Status
                        </Typography>
                        <Box sx={{ width: '100%', height: 'calc(100% - 32px)' }}>
                            <ResponsiveContainer>
                                <BarChart data={statusData} margin={{ top: 20, right: 30, left: 20, bottom: 60 }}>
                                    <CartesianGrid strokeDasharray="3 3" stroke="rgba(255, 255, 255, 0.1)" />
                                    <XAxis 
                                        dataKey="name" 
                                        angle={-45}
                                        textAnchor="end"
                                        height={60}
                                        stroke="#fff"
                                    />
                                    <YAxis stroke="#fff" />
                                    <Tooltip 
                                        contentStyle={{ 
                                            backgroundColor: '#1A1A1A',
                                            border: '1px solid rgba(255, 255, 255, 0.1)',
                                        }}
                                        labelStyle={{
                                            color: '#FFFFFF',  // text color of the label
                                        }}
                                        itemStyle={{
                                            color: '#FFFFFF',  // text color for the individual items inside the tooltip
                                        }}
                                    />
                                    <Bar dataKey="value" fill="#8884d8">
                                        {statusData.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer>
                        </Box>
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <Paper sx={{ 
                        p: 3,
                        backgroundColor: 'rgba(255, 255, 255, 0.05)',
                        backdropFilter: 'blur(10px)',
                        border: '1px solid rgba(255, 255, 255, 0.1)',
                    }}>
                        <Typography variant="h6" gutterBottom sx={{ color: '#fff' }}>
                            Recent Incidents
                        </Typography>
                        <List>
                            {recentIncidents.map((incident) => (
                                <ListItem
                                    key={incident.id}
                                    sx={{
                                        borderLeft: 3,
                                        borderColor: getStatusColor(incident.status),
                                        mb: 1,
                                        bgcolor: 'rgba(255, 255, 255, 0.02)',
                                        borderRadius: 1,
                                        '&:last-child': {
                                            mb: 0
                                        }
                                    }}
                                >
                                    <ListItemText
                                        primary={
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                {getPriorityIcon(incident.priority)}
                                                <Typography sx={{ color: '#fff' }}>
                                                    {incident.title}
                                                </Typography>
                                            </Box>
                                        }
                                        secondary={
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 1, flexWrap: 'wrap' }}>
                                                <Chip
                                                    label={incident.status}
                                                    size="small"
                                                    sx={{ bgcolor: getStatusColor(incident.status), color: 'white' }}
                                                />
                                                <Typography sx={{ color: 'rgba(255, 255, 255, 0.6)' }}>
                                                    {new Date(incident.created_at).toLocaleString()}
                                                </Typography>
                                                {incident.assigned_to && (
                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                        <Typography sx={{ color: 'rgba(255, 255, 255, 0.6)' }}>
                                                            Assigned to:
                                                        </Typography>
                                                        <Avatar
                                                            src={incident.assigned_to.profile_picture}
                                                            sx={{ 
                                                                width: 24, 
                                                                height: 24,
                                                                border: '1px solid #FF4405'
                                                            }}
                                                        >
                                                            {incident.assigned_to.first_name[0]}
                                                        </Avatar>
                                                        <Typography sx={{ color: '#fff' }}>
                                                            {`${incident.assigned_to.first_name} ${incident.assigned_to.last_name}`}
                                                        </Typography>
                                                    </Box>
                                                )}
                                            </Box>
                                        }
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Dashboard;
