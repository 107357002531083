import axios from 'axios';
import { AuthResponse, LoginData, RegisterData, Incident, ProfileUpdateData, User } from '../types';

const API_URL = process.env.REACT_APP_API_URL || 'https://incident-mgmt-be.vercel.app/api';
// const API_URL = process.env.REACT_APP_API_URL || 'https://8000--main--turquoise-nightingale-73--meghanshu.team-workspace.openana.ai/api';

const api = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

api.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    // If it's FormData, let axios set the correct content-type
    if (config.data instanceof FormData) {
        config.headers['Content-Type'] = 'multipart/form-data';
    }
    return config;
});

// Auth endpoints
export const login = async (data: LoginData): Promise<AuthResponse> => {
    const response = await api.post<AuthResponse>('/auth/login/', data);
    return response.data;
};

export const register = async (data: RegisterData): Promise<AuthResponse> => {
    const response = await api.post<AuthResponse>('/auth/register/', data);
    return response.data;
};

// User endpoints
export const getCurrentUser = async () => {
    const response = await api.get('/users/me/');
    return response.data;
};

export const getUsers = async () => {
    const response = await api.get<PaginatedResponse<User>>('/users/');
    return response.data;
};

export const updateProfile = async (data: ProfileUpdateData) => {
    const response = await api.put('/users/update_profile/', data);
    return response.data;
};

export const uploadProfilePicture = async (imageData: string) => {
    const response = await api.post('/users/upload_profile_picture/', { image: imageData });
    return response.data;
};

// Incident endpoints
export interface PaginatedResponse<T> {
    count: number;
    next: string | null;
    previous: string | null;
    results: T[];
}

export const getIncidents = async (path?: string) => {
    const response = await api.get<PaginatedResponse<Incident>>(path || '/incidents/');
    return response.data;
};

export const getIncident = async (id: number) => {
    const response = await api.get<Incident>(`/incidents/${id}/`);
    return response.data;
};

export type IncidentCreateData = FormData | Partial<Incident>;

export const createIncident = async (data: IncidentCreateData) => {
    const response = await api.post<Incident>('/incidents/', data);
    return response.data;
};

export const updateIncident = async (id: number, data: IncidentCreateData) => {
    const response = await api.put<Incident>(`/incidents/${id}/`, data);
    return response.data;
};

export const deleteIncident = async (id: number) => {
    await api.delete(`/incidents/${id}/`);
};

export const getMyIncidents = async () => {
    const response = await api.get<Incident[]>('/incidents/my_incidents/');
    return response.data;
};

export const getIncidentStatistics = async () => {
    const response = await api.get('/incidents/statistics/');
    return response.data;
};

export const getIncidentReport = async () => {
    const response = await api.get('/incidents/report/');
    return response.data;
};

export const changeIncidentStatus = async (id: number, status: string) => {
    const response = await api.post(`/incidents/${id}/change_status/`, { status });
    return response.data;
};

export const assignIncident = async (id: number, userId: number) => {
    const response = await api.post(`/incidents/${id}/assign/`, { user_id: userId });
    return response.data;
};

export const removeIncidentAttachment = async (incidentId: number, attachmentId: number) => {
    const response = await api.patch(`/incidents/${incidentId}/remove_attachment/`, { attachment_id: attachmentId });
    return response.data;
};
