import React, { useState } from 'react';
import {
    Box,
    Paper,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    IconButton,
    Chip,
    Divider,
} from '@mui/material';
import {
    Notifications as NotificationsIcon,
    Error as ErrorIcon,
    Warning as WarningIcon,
    Info as InfoIcon,
    CheckCircle as CheckCircleIcon,
    Delete as DeleteIcon,
} from '@mui/icons-material';
import LoadingSpinner from '../Layout/LoadingSpinner';

interface Notification {
    id: number;
    title: string;
    message: string;
    type: 'error' | 'warning' | 'info' | 'success';
    timestamp: string;
    read: boolean;
}

const mockNotifications: Notification[] = [
    {
        id: 1,
        title: 'Critical Incident Reported',
        message: 'A new critical incident has been reported in the system.',
        type: 'error',
        timestamp: new Date(Date.now() - 1000 * 60 * 30).toISOString(), // 30 minutes ago
        read: false,
    },
    {
        id: 2,
        title: 'Incident Resolved',
        message: 'The infrastructure incident #1234 has been resolved.',
        type: 'success',
        timestamp: new Date(Date.now() - 1000 * 60 * 60 * 2).toISOString(), // 2 hours ago
        read: true,
    },
    {
        id: 3,
        title: 'New Team Member',
        message: 'John Doe has joined the Engineering team.',
        type: 'info',
        timestamp: new Date(Date.now() - 1000 * 60 * 60 * 24).toISOString(), // 1 day ago
        read: false,
    },
    {
        id: 4,
        title: 'System Maintenance',
        message: 'Scheduled maintenance will occur in 2 hours.',
        type: 'warning',
        timestamp: new Date(Date.now() - 1000 * 60 * 60 * 48).toISOString(), // 2 days ago
        read: true,
    },
];

const Notifications: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [notifications, setNotifications] = useState<Notification[]>(mockNotifications);

    const getIcon = (type: string) => {
        switch (type) {
            case 'error':
                return <ErrorIcon sx={{ color: '#ff4842' }} />;
            case 'warning':
                return <WarningIcon sx={{ color: '#ffc107' }} />;
            case 'success':
                return <CheckCircleIcon sx={{ color: '#4caf50' }} />;
            default:
                return <InfoIcon sx={{ color: '#2196f3' }} />;
        }
    };

    const handleDelete = (id: number) => {
        setNotifications(prev => prev.filter(notification => notification.id !== id));
    };

    const formatTimestamp = (timestamp: string) => {
        const date = new Date(timestamp);
        const now = new Date();
        const diff = now.getTime() - date.getTime();
        const minutes = Math.floor(diff / 1000 / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        if (days > 0) return `${days} day${days > 1 ? 's' : ''} ago`;
        if (hours > 0) return `${hours} hour${hours > 1 ? 's' : ''} ago`;
        if (minutes > 0) return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
        return 'Just now';
    };

    if (loading) {
        return <LoadingSpinner />;
    }

    return (
        <Box sx={{ p: 3 }}>
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center', 
                mb: 4 
            }}>
                <Typography variant="h4" sx={{ color: '#fff' }}>
                    Notifications
                </Typography>
                <NotificationsIcon sx={{ color: '#FF4405', fontSize: 32 }} />
            </Box>

            <Paper sx={{ 
                backgroundColor: 'rgba(255, 255, 255, 0.05)',
                backdropFilter: 'blur(10px)',
                border: '1px solid rgba(255, 255, 255, 0.1)',
            }}>
                <List>
                    {notifications.map((notification, index) => (
                        <React.Fragment key={notification.id}>
                            <ListItem
                                sx={{
                                    py: 2,
                                    '&:hover': {
                                        backgroundColor: 'rgba(255, 255, 255, 0.05)',
                                    },
                                }}
                                secondaryAction={
                                    <IconButton 
                                        edge="end" 
                                        onClick={() => handleDelete(notification.id)}
                                        sx={{ 
                                            color: 'rgba(255, 255, 255, 0.3)',
                                            '&:hover': {
                                                color: '#FF4405',
                                            },
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                }
                            >
                                <ListItemIcon>
                                    {getIcon(notification.type)}
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            <Typography sx={{ color: '#fff' }}>
                                                {notification.title}
                                            </Typography>
                                            {!notification.read && (
                                                <Chip
                                                    label="New"
                                                    size="small"
                                                    sx={{
                                                        bgcolor: '#FF4405',
                                                        color: '#fff',
                                                        height: '20px',
                                                    }}
                                                />
                                            )}
                                        </Box>
                                    }
                                    secondary={
                                        <Box sx={{ mt: 0.5 }}>
                                            <Typography sx={{ color: 'rgba(255, 255, 255, 0.6)', fontSize: '0.875rem' }}>
                                                {notification.message}
                                            </Typography>
                                            <Typography sx={{ color: 'rgba(255, 255, 255, 0.4)', fontSize: '0.75rem', mt: 0.5 }}>
                                                {formatTimestamp(notification.timestamp)}
                                            </Typography>
                                        </Box>
                                    }
                                />
                            </ListItem>
                            {index < notifications.length - 1 && (
                                <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.1)' }} />
                            )}
                        </React.Fragment>
                    ))}
                </List>
            </Paper>
        </Box>
    );
};

export default Notifications;
