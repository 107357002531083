import React, { useCallback, useContext } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import {
    AppBar,
    Box,
    CssBaseline,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Typography,
    Divider,
    ListItemButton,
    Avatar,
} from '@mui/material';
import {
    Menu as MenuIcon,
    Dashboard as DashboardIcon,
    BugReport as BugReportIcon,
    ExitToApp as LogoutIcon,
    People as PeopleIcon,
    Person as ProfileIcon,
    Assessment as AssessmentIcon,
} from '@mui/icons-material';
import { UserRole } from '../../types';
import Footer from './Footer';
import { UserContext } from '../../App';

const drawerWidth = 240;

function AppLayout() {
    const navigate = useNavigate();
    const location = useLocation();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const { currentUser, setCurrentUser } = useContext(UserContext);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleLogout = useCallback(() => {
        // Cleanup any active chart observers
        const resizeObservers = (window as any).__RESIZE_OBSERVERS__ || [];
        resizeObservers.forEach((observer: any) => {
            try {
                observer.disconnect();
            } catch (e) {
                console.error('Error disconnecting observer:', e);
            }
        });

        // Clear any intervals or timeouts
        const highestId = window.setTimeout(() => {}, 0);
        for (let i = 0; i < highestId; i++) {
            window.clearTimeout(i);
        }

        // Remove stored data
        localStorage.removeItem('token');
        localStorage.removeItem('refresh');
        localStorage.removeItem('user');
        setCurrentUser(null);

        // Navigate to login
        setTimeout(() => {
            navigate('/login');
        }, 0);
    }, [navigate, setCurrentUser]);

    const getMenuItems = (role: UserRole) => {
        const baseItems = [
            { text: 'Dashboard', icon: <DashboardIcon />, path: '/dashboard' },
            { text: 'Reports', icon: <AssessmentIcon />, path: '/reports' },
            { text: 'Incidents', icon: <BugReportIcon />, path: '/incidents' },
            { text: 'Team', icon: <PeopleIcon />, path: '/team' },
            { text: 'Profile', icon: <ProfileIcon />, path: '/profile' },
        ];

        return baseItems;
    };

    const menuItems = currentUser ? getMenuItems(currentUser.role) : [];

    const drawer = (
        <Box sx={{ height: '100%', backgroundColor: '#111111' }}>
            <Toolbar sx={{ px: 2 }}>
                <img 
                    src="/icon.png" 
                    alt="Logo" 
                    style={{ 
                        width: '32px', 
                        height: '32px',
                        marginRight: '12px'
                    }} 
                />
                <Typography variant="h6" noWrap component="div" sx={{ color: '#fff' }}>
                    IM System
                </Typography>
            </Toolbar>
            <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.1)' }} />
            {currentUser && (
                <Box sx={{ p: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Avatar 
                        src={currentUser.profile_picture} 
                        alt={`${currentUser.first_name} ${currentUser.last_name}`}
                        sx={{ 
                            border: '2px solid #FF4405',
                            backgroundColor: 'rgba(255, 68, 5, 0.1)'
                        }}
                    />
                    <Box>
                        <Typography variant="subtitle2" sx={{ color: '#fff' }}>
                            {currentUser.first_name} {currentUser.last_name}
                        </Typography>
                        <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.6)' }}>
                            {currentUser.role}
                        </Typography>
                    </Box>
                </Box>
            )}
            <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.1)' }} />
            <List>
                {menuItems.map((item) => (
                    <ListItem key={item.text} disablePadding>
                        <ListItemButton
                            onClick={() => navigate(item.path)}
                            selected={location.pathname === item.path}
                            sx={{
                                mx: 1,
                                borderRadius: '8px',
                                '&.Mui-selected': {
                                    backgroundColor: '#FF4405',
                                    '&:hover': {
                                        backgroundColor: '#FF4405',
                                    },
                                    '& .MuiListItemIcon-root': {
                                        color: '#fff',
                                    },
                                    '& .MuiListItemText-primary': {
                                        color: '#fff',
                                    },
                                },
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 68, 5, 0.1)',
                                },
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    color: location.pathname === item.path ? '#fff' : '#FF4405',
                                    minWidth: '40px',
                                }}
                            >
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText 
                                primary={item.text} 
                                sx={{
                                    '& .MuiListItemText-primary': {
                                        color: location.pathname === item.path ? '#fff' : 'rgba(255, 255, 255, 0.87)',
                                    },
                                }}
                            />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.1)' }} />
            <List>
                <ListItem disablePadding>
                    <ListItemButton 
                        onClick={handleLogout}
                        sx={{
                            mx: 1,
                            borderRadius: '8px',
                            '&:hover': {
                                backgroundColor: 'rgba(255, 68, 5, 0.1)',
                            },
                        }}
                    >
                        <ListItemIcon sx={{ color: '#FF4405', minWidth: '40px' }}>
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText 
                            primary="Logout" 
                            sx={{
                                '& .MuiListItemText-primary': {
                                    color: 'rgba(255, 255, 255, 0.87)',
                                },
                            }}
                        />
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );

    return (
        <Box sx={{ display: 'flex', minHeight: '100vh', backgroundColor: '#111111' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                    backgroundColor: 'rgba(17, 17, 17, 0.8)',
                    backdropFilter: 'blur(10px)',
                    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                    boxShadow: 'none',
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div" sx={{ color: '#fff' }}>
                        Incident Management System
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            >
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                            backgroundColor: '#111111',
                            borderRight: '1px solid rgba(255, 255, 255, 0.1)',
                        },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                            backgroundColor: '#111111',
                            borderRight: '1px solid rgba(255, 255, 255, 0.1)',
                        },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 3,
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    backgroundColor: '#111111',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                }}
            >
                <Toolbar />
                <Box sx={{ flex: 1 }}>
                    <Outlet />
                </Box>
                <Footer />
            </Box>
        </Box>
    );
}

export default AppLayout;
