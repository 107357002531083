import React, { useState } from 'react';
import {
    Box,
    Paper,
    Typography,
    TextField,
    Button,
    Avatar,
    Grid,
    Alert,
    FormHelperText,
} from '@mui/material';
import { User, ProfileUpdateData } from '../../types';
import { updateProfile } from '../../services/api';
import LoadingSpinner from '../Layout/LoadingSpinner';

interface ProfileProps {
    user: User;
    onUpdateUser: (user: User) => void;
}

interface FieldErrors {
    [key: string]: string[];
}

const Profile: React.FC<ProfileProps> = ({ user, onUpdateUser }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [fieldErrors, setFieldErrors] = useState<FieldErrors>({});
    const [success, setSuccess] = useState<string | null>(null);
    const [formData, setFormData] = useState<ProfileUpdateData>({
        role: user.role,
        first_name: user.first_name,
        last_name: user.last_name,
        department: user.department || '',
        // current_password: '',
        // new_password: '',
        // confirm_new_password: '',
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
        // Clear field error when user starts typing
        if (fieldErrors[name]) {
            setFieldErrors(prev => ({
                ...prev,
                [name]: []
            }));
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setSuccess(null);
        setFieldErrors({});

        try {
            const updatedUser = await updateProfile(formData);
            onUpdateUser(updatedUser);
            setSuccess('Profile updated successfully');
            setFormData(prev => ({
                ...prev,
                // current_password: '',
                // new_password: '',
                // confirm_new_password: '',
            }));
        } catch (err: any) {
            if (err.response?.data && typeof err.response.data === 'object') {
                setFieldErrors(err.response.data);
            } else {
                setError('Failed to update profile');
            }
            console.error('Error updating profile:', err);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <LoadingSpinner />;
    }

    const inputStyle = {
        '& .MuiOutlinedInput-root': {
            backgroundColor: 'rgba(255, 255, 255, 0.05)', // Background for enabled state
            '& fieldset': {
                borderColor: '#FF4405', // Border color when input is enabled
            },
            '&:hover fieldset': {
                borderColor: '#ff6b2c', // Border color on hover
            },
            '&.Mui-focused fieldset': {
                borderColor: '#FF4405', // Border color when input is focused
            },
            '&.Mui-disabled': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)', // Light gray background when disabled
                borderColor: 'rgba(255, 255, 255, 0.3)',    // Gray border when disabled
            },
        },
        '& input': {
            color: '#fff', // Input text color for enabled state
            '&.Mui-disabled': {
                color: 'rgba(255, 255, 255, 0.6)', // Gray text color when input is disabled
            },
        },
        '& label': {
            color: 'rgba(255, 255, 255, 0.6)', // Label color for enabled state
        },
        '& label.Mui-focused': {
            color: '#FF4405', // Focused label color
        },
        // Disabled label style (same as enabled button)
        '& .Mui-disabled + .MuiInputLabel-root': {
            color: '#FF4405', // Same label color as enabled button
        },
    };
    


    return (
        <Box sx={{ p: 3 }}>
            <Paper sx={{
                p: 4,
                backgroundColor: 'rgba(255, 255, 255, 0.05)',
                backdropFilter: 'blur(10px)',
                border: '1px solid rgba(255, 255, 255, 0.1)',
            }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 3,
                    mb: 4
                }}>
                    <Avatar
                        src={user.profile_picture}
                        sx={{
                            width: 100,
                            height: 100,
                            border: '2px solid #FF4405',
                            backgroundColor: 'rgba(255, 68, 5, 0.1)',
                        }}
                    >
                        {user.first_name[0]}
                    </Avatar>
                    <Box>
                        <Typography variant="h4" sx={{ color: '#fff', mb: 1 }}>
                            {`${user.first_name} ${user.last_name}`}
                        </Typography>
                        <Typography sx={{ color: 'rgba(255, 255, 255, 0.6)' }}>
                            {user.email}
                        </Typography>
                        <Typography sx={{ color: 'rgba(255, 255, 255, 0.6)' }}>
                            {user.role} • {user.team}
                        </Typography>
                    </Box>
                </Box>

                {error && (
                    <Alert severity="error" sx={{ mb: 3 }}>
                        {error}
                    </Alert>
                )}

                {success && (
                    <Alert severity="success" sx={{ mb: 3 }}>
                        {success}
                    </Alert>
                )}

                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                disabled={formData.role === "GUEST" ? true : false}
                                label="First Name"
                                name="first_name"
                                value={formData.first_name}
                                onChange={handleChange}
                                sx={inputStyle}
                                error={!!fieldErrors.first_name}
                                helperText={fieldErrors.first_name?.[0]}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                disabled={formData.role === "GUEST" ? true : false}
                                label="Last Name"
                                name="last_name"
                                value={formData.last_name}
                                onChange={handleChange}
                                sx={inputStyle}
                                error={!!fieldErrors.last_name}
                                helperText={fieldErrors.last_name?.[0]}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                disabled={formData.role === "GUEST" ? true : false}
                                label="Department"
                                name="department"
                                value={formData.department}
                                onChange={handleChange}
                                sx={inputStyle}
                                error={!!fieldErrors.department}
                                helperText={fieldErrors.department?.[0]}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="h6" sx={{ color: '#fff', mb: 2, mt: 2 }}>
                                Change Password
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                disabled={formData.role === "GUEST" ? true : false}
                                type="password"
                                label="Current Password"
                                name="current_password"
                                value={formData.current_password}
                                onChange={handleChange}
                                sx={inputStyle}
                                error={!!fieldErrors.current_password}
                            />
                            {fieldErrors.current_password && (
                                <FormHelperText error sx={{ ml: 2 }}>
                                    {fieldErrors.current_password[0]}
                                </FormHelperText>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                disabled={formData.role === "GUEST" ? true : false}
                                type="password"
                                label="New Password"
                                name="new_password"
                                value={formData.new_password}
                                onChange={handleChange}
                                sx={inputStyle}
                                error={!!fieldErrors.new_password}
                                helperText={fieldErrors.new_password?.[0]}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                disabled={formData.role === "GUEST" ? true : false}
                                type="password"
                                label="Confirm New Password"
                                name="confirm_new_password"
                                value={formData.confirm_new_password}
                                onChange={handleChange}
                                sx={inputStyle}
                                error={!!fieldErrors.confirm_new_password}
                                helperText={fieldErrors.confirm_new_password?.[0]}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                variant="contained"
                                disabled={formData.role === "GUEST" ? true : false}
                                sx={{
                                    mt: 2,
                                    bgcolor: '#FF4405',  // Normal state color
                                    '&:hover': {
                                        bgcolor: '#ff6b2c',  // Hover state color
                                    },
                                    '&.Mui-disabled': {
                                        bgcolor: '#666666',  // Disabled state color
                                        color: '#ccc',  // Disabled text color
                                        '&:hover': {
                                            bgcolor: '#666666',  // Disabled hover color
                                        }
                                    }
                                }}
                            >
                                Save Changes
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </Box>
    );
};

export default Profile;
