import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import IncidentForm from './IncidentForm';
import { getIncident, updateIncident, getUsers } from '../../services/api';
import { Incident, User } from '../../types';
import { Box, Typography, CircularProgress } from '@mui/material';
import { UserContext } from '../../App';

function EditIncident() {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const { currentUser } = useContext(UserContext);
    const [incident, setIncident] = useState<Incident | null>(null);
    const [users, setUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        // Redirect GUEST users
        if (currentUser?.role === 'GUEST') {
            navigate('/dashboard');
            return;
        }

        const fetchData = async () => {
            if (!id) return;
            try {
                setLoading(true);
                const [incidentData, usersData] = await Promise.all([
                    getIncident(parseInt(id)),
                    getUsers()
                ]);
                setIncident(incidentData);
                setUsers(usersData.results);
                setError(null);
            } catch (err) {
                setError('Failed to load data');
                console.error('Error fetching data:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [id, navigate, currentUser]);

    // If user is GUEST, show access denied message
    if (currentUser?.role === 'GUEST') {
        return (
            <Box sx={{ color: 'error.main', p: 2 }}>
                <Typography variant="h6">
                    Access Denied
                </Typography>
                <Typography>
                    Guest users do not have permission to edit incidents.
                </Typography>
            </Box>
        );
    }

    const handleSubmit = async (formData: FormData) => {
        if (!id) return;
        try {
            await updateIncident(parseInt(id), formData);
            navigate('/incidents');
        } catch (error) {
            // Let the form component handle the error
            throw error;
        }
    };

    if (loading) {
        return (
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                height: '200px' 
            }}>
                <CircularProgress sx={{ color: '#FF4405' }} />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ p: 3 }}>
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    if (!incident) {
        return (
            <Box sx={{ p: 3 }}>
                <Typography sx={{ color: '#fff' }}>Incident not found</Typography>
            </Box>
        );
    }

    return (
        <IncidentForm 
            incident={incident} 
            onSubmit={handleSubmit} 
            users={users}
        />
    );
}

export default EditIncident;
