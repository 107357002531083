import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';

const Footer: React.FC = () => {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: 'auto',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        backdropFilter: 'blur(10px)',
        borderTop: '1px solid rgba(255, 255, 255, 0.1)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography 
        variant="body2" 
        sx={{ 
          color: 'rgba(255, 255, 255, 0.7)',
          display: 'flex',
          alignItems: 'center',
          gap: 0.5
        }}
      >
        Built with <FavoriteIcon sx={{ color: '#FF6B2C', fontSize: 16 }} /> by{' '}
        <Link
          href="https://openana.ai"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ 
            color: '#FF6B2C',
            textDecoration: 'none',
            '&:hover': {
              color: '#FF8F5C',
              textDecoration: 'none'
            }
          }}
        >
          Ana
        </Link>
      </Typography>
    </Box>
  );
};

export default Footer;
