import React, { useState, useEffect, createContext, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import Dashboard from './components/Dashboard/Dashboard';
import IncidentList from './components/Incidents/IncidentList';
import CreateIncident from './components/Incidents/CreateIncident';
import EditIncident from './components/Incidents/EditIncident';
import Teams from './components/Teams/Teams';
import Reports from './components/Reports/Reports';
import Profile from './components/Profile/Profile';
import Notifications from './components/Notifications/Notifications';
import AppLayout from './components/Layout/AppLayout';
import { User } from './types';
import { getCurrentUser } from './services/api';

// Create context for user data
export const UserContext = createContext<{
  currentUser: User | null;
  setCurrentUser: (user: User | null) => void;
}>({
  currentUser: null,
  setCurrentUser: () => {},
});

interface ProtectedRouteProps {
  children: React.ReactElement;
}

function ProtectedRoute({ children }: ProtectedRouteProps) {
  const token = localStorage.getItem('token');
  if (!token) {
    return <Navigate to="/login" replace />;
  }
  return children;
}

function App() {
  const [currentUser, setCurrentUser] = useState<User | null>(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      getCurrentUser()
        .then(user => {
          setCurrentUser(user);
          localStorage.setItem('user', JSON.stringify(user));
        })
        .catch(() => {
          localStorage.removeItem('token');
          localStorage.removeItem('refresh');
          localStorage.removeItem('user');
          setCurrentUser(null);
        });
    }
  }, []);

  return (
    <UserContext.Provider value={{ currentUser, setCurrentUser }}>
      <Router>
        <CssBaseline />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <AppLayout />
              </ProtectedRoute>
            }
          >
            <Route index element={<Navigate to="/dashboard" replace />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="incidents" element={<IncidentList />} />
            <Route path="incidents/new" element={<CreateIncident />} />
            <Route path="incidents/:id/edit" element={<EditIncident />} />
            <Route path="team" element={<Teams />} />
            <Route path="reports" element={<Reports />} />
            <Route path="notifications" element={<Notifications />} />
            <Route 
              path="profile" 
              element={
                currentUser ? (
                  <Profile user={currentUser} onUpdateUser={setCurrentUser} />
                ) : null
              } 
            />
          </Route>
        </Routes>
      </Router>
    </UserContext.Provider>
  );
}

export default App;
