import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    Typography,
    Avatar,
    Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { User } from '../../types';
import { getUsers } from '../../services/api';
import Register from '../Auth/Register';
import LoadingSpinner from '../Layout/LoadingSpinner';
import { UserContext } from '../../App';

const Teams: React.FC = () => {
    const navigate = useNavigate();
    const { currentUser } = useContext(UserContext);
    const [users, setUsers] = useState<User[]>([]);
    const [openAddUser, setOpenAddUser] = useState(false);
    const [loading, setLoading] = useState(true);
    const [nextPage, setNextPage] = useState<string | null>(null);
    const [previousPage, setPreviousPage] = useState<string | null>(null);

    useEffect(() => {
        // // Redirect GUEST users
        // if (currentUser?.role === 'GUEST') {
        //     navigate('/dashboard');
        //     return;
        // }

        fetchUsers('/users/');
    }, [navigate, currentUser]);

    const extractPathFromUrl = (url: string) => {
        const parsedUrl = new URL(url);
        // Remove '/api' from the beginning of the pathname
        const path = parsedUrl.pathname.replace('/api', '') + parsedUrl.search;
        return path;
    };

    const fetchUsers = async (path: string) => {
        try {
            const response = await fetch(`https://incident-mgmt-be.vercel.app/api${path}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const data = await response.json();
            setUsers(data.results);
            setNextPage(data.next ? extractPathFromUrl(data.next) : null);
            setPreviousPage(data.previous ? extractPathFromUrl(data.previous) : null);
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleAddUser = () => {
        setOpenAddUser(true);
    };

    const handleCloseAddUser = () => {
        setOpenAddUser(false);
        fetchUsers('/users/');
    };

    const handleNextPage = () => {
        if (nextPage) {
            fetchUsers(nextPage);
        }
    };

    const handlePreviousPage = () => {
        if (previousPage) {
            fetchUsers(previousPage);
        }
    };

    const canAddUsers = currentUser?.role === 'ADMIN';

    // If user is GUEST, show access denied message
    // if (currentUser?.role === 'GUEST') {
    //     return (
    //         <Box sx={{ color: 'error.main', p: 2 }}>
    //             <Typography variant="h6">
    //                 Access Denied
    //             </Typography>
    //             <Typography>
    //                 Guest users do not have permission to access team management.
    //             </Typography>
    //         </Box>
    //     );
    // }

    if (loading) {
        return <LoadingSpinner />;
    }

    return (
        <Box sx={{ p: 3 }}>
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center', 
                mb: 4 
            }}>
                <Typography variant="h4" sx={{ color: '#fff' }}>
                    Team Management
                </Typography>
                {canAddUsers && (
                    <Button
                        variant="contained"
                        onClick={handleAddUser}
                        sx={{
                            bgcolor: '#FF4405',
                            '&:hover': {
                                bgcolor: '#ff6b2c',
                            },
                        }}
                    >
                        Add User
                    </Button>
                )}
            </Box>

            <TableContainer 
                component={Paper}
                sx={{
                    backgroundColor: 'rgba(255, 255, 255, 0.05)',
                    backdropFilter: 'blur(10px)',
                    border: '1px solid rgba(255, 255, 255, 0.1)',
                    mb: 2
                }}
            >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ color: 'rgba(255, 255, 255, 0.6)', borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>User</TableCell>
                            <TableCell sx={{ color: 'rgba(255, 255, 255, 0.6)', borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>Email</TableCell>
                            <TableCell sx={{ color: 'rgba(255, 255, 255, 0.6)', borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>Role</TableCell>
                            <TableCell sx={{ color: 'rgba(255, 255, 255, 0.6)', borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>Team</TableCell>
                            <TableCell sx={{ color: 'rgba(255, 255, 255, 0.6)', borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>Department</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((user) => (
                            <TableRow key={user.id}>
                                <TableCell 
                                    sx={{ 
                                        borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
                                    }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                        <Avatar 
                                            src={user.profile_picture} 
                                            alt={`${user.first_name} ${user.last_name}`}
                                            sx={{ 
                                                border: '1px solid #FF4405',
                                                bgcolor: 'rgba(255, 68, 5, 0.1)'
                                            }}
                                        />
                                        <Typography sx={{ color: '#fff' }}>
                                            {`${user.first_name} ${user.last_name}`}
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <TableCell 
                                    sx={{ 
                                        color: '#fff',
                                        borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
                                    }}
                                >
                                    {user.email}
                                </TableCell>
                                <TableCell 
                                    sx={{ 
                                        color: '#fff',
                                        borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
                                    }}
                                >
                                    {user.role}
                                </TableCell>
                                <TableCell 
                                    sx={{ 
                                        color: '#fff',
                                        borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
                                    }}
                                >
                                    {user.team}
                                </TableCell>
                                <TableCell 
                                    sx={{ 
                                        color: '#fff',
                                        borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
                                    }}
                                >
                                    {user.department}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Navigation Controls */}
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'center',
                gap: 2,
                mt: 2
            }}>
                <Button
                    onClick={handlePreviousPage}
                    disabled={!previousPage}
                    startIcon={<NavigateBeforeIcon />}
                    sx={{
                        color: '#fff',
                        borderColor: 'rgba(255, 255, 255, 0.23)',
                        '&:disabled': {
                            color: 'rgba(255, 255, 255, 0.3)',
                        },
                        '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.05)',
                        },
                    }}
                    variant="outlined"
                >
                    Previous
                </Button>
                <Button
                    onClick={handleNextPage}
                    disabled={!nextPage}
                    endIcon={<NavigateNextIcon />}
                    sx={{
                        color: '#fff',
                        borderColor: 'rgba(255, 255, 255, 0.23)',
                        '&:disabled': {
                            color: 'rgba(255, 255, 255, 0.3)',
                        },
                        '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.05)',
                        },
                    }}
                    variant="outlined"
                >
                    Next
                </Button>
            </Box>

            <Dialog
                open={openAddUser}
                onClose={handleCloseAddUser}
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    sx: {
                        backgroundColor: 'rgba(255, 255, 255, 0.05)',
                        backdropFilter: 'blur(10px)',
                        border: '1px solid rgba(255, 255, 255, 0.1)',
                    }
                }}
            >
                <DialogTitle>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h6" sx={{ color: '#fff' }}>
                            Add New User
                        </Typography>
                        <IconButton
                            edge="end"
                            onClick={handleCloseAddUser}
                            aria-label="close"
                            sx={{ color: '#fff' }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Register />
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default Teams;
