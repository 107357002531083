import React, { useState, useRef } from 'react';
import {
    Box,
    Button,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Grid,
    Paper,
    Typography,
    SelectChangeEvent,
    Chip,
    IconButton,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Link,
    Tooltip,
} from '@mui/material';
import {
    CloudUpload as CloudUploadIcon,
    Delete as DeleteIcon,
    Add as AddIcon,
    OpenInNew as OpenInNewIcon,
} from '@mui/icons-material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Incident, User, IncidentAttachment } from '../../types';
import { removeIncidentAttachment } from '../../services/api';

interface IncidentFormProps {
    incident?: Incident;
    onSubmit: (data: FormData) => Promise<void>;
    users?: User[];
}

interface FieldErrors {
    [key: string]: string[];
}

const IncidentForm: React.FC<IncidentFormProps> = ({ incident, onSubmit, users }) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [formData, setFormData] = useState<Partial<Incident>>(
        incident || {
            title: '',
            description: '',
            incident_type: 'APPLICATION',
            priority: 'MEDIUM',
            status: 'REPORTED',
            affected_component: '',
            user_impact: 'LOW',
            business_impact: 1,
            start_time: new Date().toISOString(),
            end_time: undefined,
            assigned_to_id: undefined,
            tags: [],
        }
    );
    const [files, setFiles] = useState<File[]>([]);
    const [existingFiles, setExistingFiles] = useState<IncidentAttachment[]>(
        incident?.attachments || []
    );
    const [newTag, setNewTag] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [fieldErrors, setFieldErrors] = useState<FieldErrors>({});

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSelectChange = (e: SelectChangeEvent<string | number>) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleDateChange = (name: string) => (date: Date | null) => {
        if (date) {
            setFormData(prev => ({
                ...prev,
                [name]: date.toISOString()
            }));
        }
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const newFiles = Array.from(e.target.files);
            setFiles(prev => [...prev, ...newFiles]);
        }
    };

    const handleRemoveFile = (index: number) => {
        setFiles(prev => prev.filter((_, i) => i !== index));
    };

    const handleRemoveExistingFile = async (attachmentId: number) => {
        try {
            if (incident?.id) {
                await removeIncidentAttachment(incident.id, attachmentId);
            }
            setExistingFiles(prev => prev.filter(file => file.id !== attachmentId));
        } catch (error) {
            console.error('Error removing attachment:', error);
            setError('Failed to remove attachment');
        }
    };

    const handleAddTag = () => {
        if (newTag && !formData.tags?.includes(newTag)) {
            setFormData(prev => ({
                ...prev,
                tags: [...(prev.tags || []), newTag]
            }));
            setNewTag('');
        }
    };

    const handleRemoveTag = (tagToRemove: string) => {
        setFormData(prev => ({
            ...prev,
            tags: prev.tags?.filter(tag => tag !== tagToRemove) || []
        }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setFieldErrors({});

        try {
            const formDataToSend = new FormData();

            Object.entries(formData).forEach(([key, value]) => {
                if (key === 'tags') {
                    formDataToSend.append(key, JSON.stringify(value));
                } else if (value !== undefined && value !== null) {
                    formDataToSend.append(key, value.toString());
                }
            });

            formDataToSend.append('existing_attachments', JSON.stringify(
                existingFiles.map(file => file.id)
            ));

            files.forEach(file => {
                formDataToSend.append('uploaded_files', file);
            });

            await onSubmit(formDataToSend);
        } catch (err: any) {
            if (err.response?.data) {
                // Handle field-specific errors
                if (typeof err.response.data === 'object') {
                    setFieldErrors(err.response.data);
                    // Show the first error as the main error message
                    const firstError = Object.values(err.response.data)[0];
                    if (Array.isArray(firstError) && firstError.length > 0) {
                        setError(firstError[0]);
                    }
                } else {
                    setError('Failed to save incident');
                }
            } else {
                setError('Failed to save incident');
            }
            console.error('Error submitting form:', err);
        } finally {
            setLoading(false);
        }
    };

    const getFieldError = (fieldName: string): string => {
        if (fieldErrors[fieldName]) {
            return fieldErrors[fieldName].join(', ');
        }
        return '';
    };

    const inputStyle = {
        '& .MuiOutlinedInput-root': {
            backgroundColor: 'rgba(255, 255, 255, 0.05)',
            '& fieldset': {
                borderColor: '#FF4405',
                borderWidth: '1px',
            },
            '&:hover fieldset': {
                borderColor: '#ff6b2c',
                borderWidth: '1px',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#FF4405',
                borderWidth: '1px',
            },
        },
        '& input, & .MuiSelect-select, & textarea': {
            color: '#fff',
        },
        '& label': {
            color: 'rgba(255, 255, 255, 0.6)',
        },
        '& label.Mui-focused': {
            color: '#FF4405',
        },
        '& .MuiFormHelperText-root': {
            color: '#d32f2f',
        },
    };

    const selectStyle = {
        ...inputStyle,
        '& .MuiSelect-select': {
            backgroundColor: 'rgba(255, 255, 255, 0.05)',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#FF4405',
            borderWidth: '1px',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#ff6b2c',
            borderWidth: '1px',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#FF4405',
            borderWidth: '1px',
        },
    };

    const menuProps = {
        PaperProps: {
            sx: {
                backgroundColor: 'rgba(30, 30, 30, 0.95)',
                border: '1px solid #FF4405',
                borderRadius: '4px',
                marginTop: '8px',
                '& .MuiMenuItem-root': {
                    color: '#fff',
                    '&:hover': {
                        backgroundColor: 'rgba(255, 68, 5, 0.1)',
                    },
                    '&.Mui-selected': {
                        backgroundColor: 'rgba(255, 68, 5, 0.2)',
                        '&:hover': {
                            backgroundColor: 'rgba(255, 68, 5, 0.3)',
                        },
                    },
                },
            },
        },
    };

    const datePickerStyle = {
        width: '100%',
        '& .MuiOutlinedInput-root': {
            backgroundColor: 'rgba(255, 255, 255, 0.05)',
            '& fieldset': {
                borderColor: '#FF4405',
                borderWidth: '1px',
            },
            '&:hover fieldset': {
                borderColor: '#ff6b2c',
                borderWidth: '1px',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#FF4405',
                borderWidth: '1px',
            },
        },
        '& input': {
            color: '#fff',
        },
        '& .MuiIconButton-root': {
            color: '#FF4405',
        },
        '& .MuiSvgIcon-root': {
            color: '#FF4405',
        },
        '& .MuiFormLabel-root': {
            color: 'rgba(255, 255, 255, 0.6)',
            '&.Mui-focused': {
                color: '#FF4405',
            },
        },
        '& .MuiFormHelperText-root': {
            color: '#d32f2f',
        },
    };

    return (
        <Box sx={{ p: 3 }}>
            <Paper sx={{
                p: 3,
                backgroundColor: 'rgba(255, 255, 255, 0.05)',
                backdropFilter: 'blur(10px)',
                border: '1px solid rgba(255, 255, 255, 0.1)',
            }}>
                <Typography variant="h5" gutterBottom sx={{ color: '#fff' }}>
                    {incident ? 'Edit Incident' : 'Create New Incident'}
                </Typography>

                {error && (
                    <Box sx={{ 
                        mb: 2, 
                        p: 2, 
                        bgcolor: 'rgba(211, 47, 47, 0.1)', 
                        borderRadius: 1,
                        border: '1px solid #d32f2f'
                    }}>
                        <Typography color="error">
                            {error}
                        </Typography>
                    </Box>
                )}

                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                name="title"
                                label="Title"
                                value={formData.title || ''}
                                onChange={handleInputChange}
                                error={!!getFieldError('title')}
                                helperText={getFieldError('title')}
                                sx={inputStyle}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                multiline
                                rows={4}
                                name="description"
                                label="Description"
                                value={formData.description || ''}
                                onChange={handleInputChange}
                                error={!!getFieldError('description')}
                                helperText={getFieldError('description')}
                                sx={inputStyle}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth error={!!getFieldError('incident_type')}>
                                <InputLabel sx={{ color: 'rgba(255, 255, 255, 0.6)' }}>Incident Type</InputLabel>
                                <Select
                                    name="incident_type"
                                    value={formData.incident_type || 'APPLICATION'}
                                    onChange={handleSelectChange}
                                    label="Incident Type"
                                    sx={selectStyle}
                                    MenuProps={menuProps}
                                >
                                    <MenuItem value="APPLICATION">Application</MenuItem>
                                    <MenuItem value="INFRASTRUCTURE">Infrastructure</MenuItem>
                                    <MenuItem value="SECURITY">Security</MenuItem>
                                </Select>
                                {getFieldError('incident_type') && (
                                    <Typography color="error" variant="caption" sx={{ mt: 1 }}>
                                        {getFieldError('incident_type')}
                                    </Typography>
                                )}
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth error={!!getFieldError('priority')}>
                                <InputLabel sx={{ color: 'rgba(255, 255, 255, 0.6)' }}>Priority</InputLabel>
                                <Select
                                    name="priority"
                                    value={formData.priority || 'MEDIUM'}
                                    onChange={handleSelectChange}
                                    label="Priority"
                                    sx={selectStyle}
                                    MenuProps={menuProps}
                                >
                                    <MenuItem value="LOW">Low</MenuItem>
                                    <MenuItem value="MEDIUM">Medium</MenuItem>
                                    <MenuItem value="HIGH">High</MenuItem>
                                    <MenuItem value="CRITICAL">Critical</MenuItem>
                                </Select>
                                {getFieldError('priority') && (
                                    <Typography color="error" variant="caption" sx={{ mt: 1 }}>
                                        {getFieldError('priority')}
                                    </Typography>
                                )}
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth error={!!getFieldError('status')}>
                                <InputLabel sx={{ color: 'rgba(255, 255, 255, 0.6)' }}>Status</InputLabel>
                                <Select
                                    name="status"
                                    value={formData.status || 'REPORTED'}
                                    onChange={handleSelectChange}
                                    label="Status"
                                    sx={selectStyle}
                                    MenuProps={menuProps}
                                >
                                    <MenuItem value="REPORTED">Reported</MenuItem>
                                    <MenuItem value="ACKNOWLEDGED">Acknowledged</MenuItem>
                                    <MenuItem value="ASSIGNED">Assigned</MenuItem>
                                    <MenuItem value="DIAGNOSED">Diagnosed</MenuItem>
                                    <MenuItem value="MITIGATED">Mitigated</MenuItem>
                                    <MenuItem value="RESOLVED">Resolved</MenuItem>
                                    <MenuItem value="CLOSED">Closed</MenuItem>
                                </Select>
                                {getFieldError('status') && (
                                    <Typography color="error" variant="caption" sx={{ mt: 1 }}>
                                        {getFieldError('status')}
                                    </Typography>
                                )}
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth error={!!getFieldError('user_impact')}>
                                <InputLabel sx={{ color: 'rgba(255, 255, 255, 0.6)' }}>User Impact</InputLabel>
                                <Select
                                    name="user_impact"
                                    value={formData.user_impact || 'LOW'}
                                    onChange={handleSelectChange}
                                    label="User Impact"
                                    sx={selectStyle}
                                    MenuProps={menuProps}
                                >
                                    <MenuItem value="LOW">Low</MenuItem>
                                    <MenuItem value="MEDIUM">Medium</MenuItem>
                                    <MenuItem value="HIGH">High</MenuItem>
                                </Select>
                                {getFieldError('user_impact') && (
                                    <Typography color="error" variant="caption" sx={{ mt: 1 }}>
                                        {getFieldError('user_impact')}
                                    </Typography>
                                )}
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DateTimePicker
                                    label="Start Time"
                                    value={formData.start_time ? new Date(formData.start_time) : null}
                                    onChange={handleDateChange('start_time')}
                                    sx={{
                                        ...datePickerStyle,
                                        '& .MuiOutlinedInput-root': {
                                            ...datePickerStyle['& .MuiOutlinedInput-root'],
                                            ...(!!getFieldError('start_time') && {
                                                '& fieldset': {
                                                    borderColor: '#d32f2f !important',
                                                },
                                            }),
                                        },
                                    }}
                                    slotProps={{
                                        textField: {
                                            variant: 'outlined',
                                            error: !!getFieldError('start_time'),
                                            helperText: getFieldError('start_time'),
                                            InputLabelProps: {
                                                sx: { color: 'rgba(255, 255, 255, 0.6)' }
                                            }
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DateTimePicker
                                    label="End Time"
                                    value={formData.end_time ? new Date(formData.end_time) : null}
                                    onChange={handleDateChange('end_time')}
                                    sx={{
                                        ...datePickerStyle,
                                        '& .MuiOutlinedInput-root': {
                                            ...datePickerStyle['& .MuiOutlinedInput-root'],
                                            ...(!!getFieldError('end_time') && {
                                                '& fieldset': {
                                                    borderColor: '#d32f2f !important',
                                                },
                                            }),
                                        },
                                    }}
                                    slotProps={{
                                        textField: {
                                            variant: 'outlined',
                                            error: !!getFieldError('end_time'),
                                            helperText: getFieldError('end_time'),
                                            InputLabelProps: {
                                                sx: { color: 'rgba(255, 255, 255, 0.6)' }
                                            }
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                name="affected_component"
                                label="Affected Component"
                                value={formData.affected_component || ''}
                                onChange={handleInputChange}
                                error={!!getFieldError('affected_component')}
                                helperText={getFieldError('affected_component')}
                                sx={inputStyle}
                            />
                        </Grid>

                        {users && (
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth error={!!getFieldError('assigned_to_id')}>
                                    <InputLabel sx={{ color: 'rgba(255, 255, 255, 0.6)' }}>Assign To</InputLabel>
                                    <Select
                                        name="assigned_to_id"
                                        value={formData.assigned_to_id || ''}
                                        onChange={handleSelectChange}
                                        label="Assign To"
                                        sx={selectStyle}
                                        MenuProps={menuProps}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {users.map((user: User) => (
                                            <MenuItem key={user.id} value={user.id}>
                                                {`${user.first_name} ${user.last_name} (${user.team})`}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {getFieldError('assigned_to_id') && (
                                        <Typography color="error" variant="caption" sx={{ mt: 1 }}>
                                            {getFieldError('assigned_to_id')}
                                        </Typography>
                                    )}
                                </FormControl>
                            </Grid>
                        )}

                        {/* Tags Input */}
                        <Grid item xs={12}>
                            <Box sx={{ mb: 2 }}>
                                <Typography variant="subtitle1" sx={{ color: '#fff', mb: 1 }}>
                                    Tags
                                </Typography>
                                <Box sx={{ display: 'flex', gap: 1, mb: 1 }}>
                                    <TextField
                                        fullWidth
                                        value={newTag}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewTag(e.target.value)}
                                        placeholder="Add a tag"
                                        error={!!getFieldError('tags')}
                                        helperText={getFieldError('tags')}
                                        sx={inputStyle}
                                    />
                                    <Button
                                        onClick={handleAddTag}
                                        variant="contained"
                                        sx={{
                                            bgcolor: '#FF4405',
                                            '&:hover': {
                                                bgcolor: '#ff6b2c',
                                            },
                                        }}
                                    >
                                        <AddIcon />
                                    </Button>
                                </Box>
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                    {formData.tags?.map((tag: string) => (
                                        <Chip
                                            key={tag}
                                            label={tag}
                                            onDelete={() => handleRemoveTag(tag)}
                                            sx={{
                                                bgcolor: 'rgba(255, 68, 5, 0.1)',
                                                color: '#fff',
                                                borderColor: '#FF4405',
                                            }}
                                        />
                                    ))}
                                </Box>
                            </Box>
                        </Grid>

                        {/* File Upload Section */}
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" sx={{ color: '#fff', mb: 1 }}>
                                Attachments
                            </Typography>

                            {/* Existing Files */}
                            {existingFiles.length > 0 && (
                                <Box sx={{ mb: 2 }}>
                                    <Typography variant="subtitle2" sx={{ color: '#fff', mb: 1 }}>
                                        Existing Files
                                    </Typography>
                                    <List>
                                        {existingFiles.map((attachment: IncidentAttachment) => (
                                            <ListItem
                                                key={attachment.id}
                                                sx={{
                                                    bgcolor: 'rgba(255, 255, 255, 0.05)',
                                                    borderRadius: 1,
                                                    mb: 1
                                                }}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                            <Link
                                                                href={attachment.file}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                sx={{
                                                                    color: '#fff',
                                                                    textDecoration: 'none',
                                                                    '&:hover': {
                                                                        textDecoration: 'underline',
                                                                        color: '#FF4405'
                                                                    }
                                                                }}
                                                            >
                                                                {attachment.file_name}
                                                            </Link>
                                                            <Tooltip title="Open in new tab">
                                                                <IconButton
                                                                    size="small"
                                                                    onClick={() => window.open(attachment.file, '_blank')}
                                                                    sx={{ color: '#FF4405' }}
                                                                >
                                                                    <OpenInNewIcon fontSize="small" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Box>
                                                    }
                                                    secondary={
                                                        <Typography sx={{ color: 'rgba(255, 255, 255, 0.6)' }}>
                                                            {`${(attachment.file_size / 1024).toFixed(2)} KB • ${attachment.file_type.toUpperCase()}`}
                                                        </Typography>
                                                    }
                                                />
                                                <ListItemSecondaryAction>
                                                    <IconButton
                                                        edge="end"
                                                        onClick={() => handleRemoveExistingFile(attachment.id)}
                                                        sx={{ color: '#FF4405' }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Box>
                            )}

                            {/* New Files Upload */}
                            <input
                                type="file"
                                ref={fileInputRef}
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                                multiple
                            />
                            <Button
                                variant="outlined"
                                startIcon={<CloudUploadIcon />}
                                onClick={() => fileInputRef.current?.click()}
                                sx={{
                                    color: '#fff',
                                    borderColor: '#FF4405',
                                    '&:hover': {
                                        borderColor: '#ff6b2c',
                                        bgcolor: 'rgba(255, 68, 5, 0.1)',
                                    },
                                    mb: 2
                                }}
                            >
                                Upload New Files
                            </Button>

                            {/* New Files List */}
                            {files.length > 0 && (
                                <Box sx={{ mt: 2 }}>
                                    <Typography variant="subtitle2" sx={{ color: '#fff', mb: 1 }}>
                                        New Files
                                    </Typography>
                                    <List>
                                        {files.map((file: File, index: number) => (
                                            <ListItem
                                                key={index}
                                                sx={{
                                                    bgcolor: 'rgba(255, 255, 255, 0.05)',
                                                    borderRadius: 1,
                                                    mb: 1
                                                }}
                                            >
                                                <ListItemText
                                                    primary={file.name}
                                                    secondary={`${(file.size / 1024).toFixed(2)} KB`}
                                                    sx={{
                                                        '& .MuiListItemText-primary': { color: '#fff' },
                                                        '& .MuiListItemText-secondary': { color: 'rgba(255, 255, 255, 0.6)' }
                                                    }}
                                                />
                                                <ListItemSecondaryAction>
                                                    <IconButton
                                                        edge="end"
                                                        onClick={() => handleRemoveFile(index)}
                                                        sx={{ color: '#FF4405' }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Box>
                            )}
                        </Grid>

                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    disabled={loading}
                                    sx={{
                                        bgcolor: '#FF4405',
                                        '&:hover': {
                                            bgcolor: '#ff6b2c',
                                        },
                                    }}
                                >
                                    {loading ? 'Saving...' : (incident ? 'Update' : 'Create')}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </Box>
    );
};

export default IncidentForm;
