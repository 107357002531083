import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, CircularProgress, Typography } from '@mui/material';
import IncidentForm from './IncidentForm';
import { createIncident, getUsers } from '../../services/api';
import { User } from '../../types';
import { UserContext } from '../../App';

function CreateIncident() {
    const navigate = useNavigate();
    const { currentUser } = useContext(UserContext);
    const [users, setUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        // Redirect GUEST users
        if (currentUser?.role === 'GUEST') {
            navigate('/dashboard');
            return;
        }

        const fetchUsers = async () => {
            try {
                const response = await getUsers();
                setUsers(response.results);
            } catch (err) {
                console.error('Error fetching users:', err);
                setError('Failed to load users');
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, [navigate, currentUser]);

    // If user is GUEST, show access denied message
    if (currentUser?.role === 'GUEST') {
        return (
            <Box sx={{ color: 'error.main', p: 2 }}>
                <Typography variant="h6">
                    Access Denied
                </Typography>
                <Typography>
                    Guest users do not have permission to create incidents.
                </Typography>
            </Box>
        );
    }

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                <CircularProgress sx={{ color: '#FF4405' }} />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ color: 'error.main', p: 2 }}>
                {error}
            </Box>
        );
    }

    const handleSubmit = async (formData: FormData) => {
        try {
            await createIncident(formData);
            navigate('/incidents');
        } catch (error) {
            // Let the form component handle the error
            throw error;
        }
    };

    return (
        <IncidentForm onSubmit={handleSubmit} users={users} />
    );
}

export default CreateIncident;
