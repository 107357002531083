import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Container,
    Box,
    Typography,
    TextField,
    Button,
    Alert,
} from '@mui/material';
import { login, getCurrentUser } from '../../services/api';
import { LoginData } from '../../types';
import Footer from '../Layout/Footer';
import { UserContext } from '../../App';

const Login: React.FC = () => {
    const navigate = useNavigate();
    const { setCurrentUser } = useContext(UserContext);
    const [formData, setFormData] = useState<LoginData>({
        email: '',
        password: '',
    });
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setError('');
        setLoading(true);

        try {
            // First login to get tokens
            const response = await login(formData);
            localStorage.setItem('token', response.token);
            localStorage.setItem('refresh', response.refresh);

            // Then get user details
            const user = await getCurrentUser();
            
            // Update UI with user details
            setCurrentUser(user);
            localStorage.setItem('user', JSON.stringify(user));

            navigate('/dashboard');
        } catch (err) {
            setError('Invalid credentials');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            minHeight: '100vh',
            background: 'linear-gradient(145deg, rgba(0, 0, 0, 0.95) 0%, rgba(0, 0, 0, 0.85) 100%)',
        }}>
            <Container component="main" maxWidth="xs" sx={{ flex: 1 }}>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Box sx={{ mb: 4, textAlign: 'center' }}>
                        <img 
                            src="/icon.png" 
                            alt="Logo" 
                            style={{ 
                                width: '64px', 
                                height: '64px',
                                marginBottom: '16px'
                            }} 
                        />
                        <Typography 
                            component="h1" 
                            variant="h4" 
                            sx={{ 
                                color: '#fff',
                                fontWeight: 500,
                                mb: 1
                            }}
                        >
                            Welcome back!
                        </Typography>
                        <Typography 
                            variant="body1" 
                            sx={{ 
                                color: 'rgba(255, 255, 255, 0.7)',
                                mb: 3
                            }}
                        >
                            Please enter your details
                        </Typography>
                    </Box>

                    {error && (
                        <Alert 
                            severity="error" 
                            sx={{ 
                                mb: 2,
                                width: '100%',
                                backgroundColor: 'rgba(211, 47, 47, 0.1)',
                                color: '#ff5252',
                            }}
                        >
                            {error}
                        </Alert>
                    )}

                    <Box 
                        component="form" 
                        onSubmit={handleSubmit} 
                        sx={{ 
                            width: '100%',
                        }}
                    >
                        <Typography 
                            variant="body2" 
                            sx={{ 
                                mb: 1,
                                color: 'rgba(255, 255, 255, 0.7)'
                            }}
                        >
                            Email
                        </Typography>
                        <TextField
                            required
                            fullWidth
                            id="email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={formData.email}
                            onChange={handleChange}
                            sx={{
                                mb: 3,
                                '& .MuiOutlinedInput-root': {
                                    backgroundColor: 'rgba(255, 255, 255, 0.05)',
                                    '& fieldset': {
                                        borderColor: '#FF6B2C',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#FF8F5C',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#FF6B2C',
                                    },
                                },
                                '& input': {
                                    color: '#fff',
                                },
                            }}
                            placeholder="Enter your email"
                            disabled={loading}
                        />

                        <Typography 
                            variant="body2" 
                            sx={{ 
                                mb: 1,
                                color: 'rgba(255, 255, 255, 0.7)'
                            }}
                        >
                            Password
                        </Typography>
                        <TextField
                            required
                            fullWidth
                            name="password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={formData.password}
                            onChange={handleChange}
                            sx={{
                                mb: 3,
                                '& .MuiOutlinedInput-root': {
                                    backgroundColor: 'rgba(255, 255, 255, 0.05)',
                                    '& fieldset': {
                                        borderColor: '#FF6B2C',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#FF8F5C',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#FF6B2C',
                                    },
                                },
                                '& input': {
                                    color: '#fff',
                                },
                            }}
                            placeholder="Enter your password"
                            disabled={loading}
                        />

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={loading}
                            sx={{
                                mt: 2,
                                mb: 3,
                                py: 1.5,
                                backgroundColor: '#FF6B2C',
                                '&:hover': {
                                    backgroundColor: '#FF8F5C',
                                },
                                '&.Mui-disabled': {
                                    backgroundColor: 'rgba(255, 107, 44, 0.5)',
                                    color: 'rgba(255, 255, 255, 0.7)',
                                },
                            }}
                        >
                            {loading ? 'Signing in...' : 'Sign In'}
                        </Button>

                        <Box sx={{ textAlign: 'center' }}>
                            <Button
                                variant="text"
                                onClick={() => navigate('/register')}
                                disabled={loading}
                                sx={{
                                    color: '#FF6B2C',
                                    textTransform: 'none',
                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                        color: '#FF8F5C',
                                    },
                                }}
                            >
                                Don't have an account? Sign Up
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Container>
            <Footer />
        </Box>
    );
};

export default Login;
