import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

const LoadingSpinner: React.FC = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '200px',
                gap: 2,
            }}
        >
            <CircularProgress
                size={50}
                thickness={4}
                sx={{
                    color: '#FF4405',
                    '& .MuiCircularProgress-circle': {
                        strokeLinecap: 'round',
                    },
                    animation: 'pulse 1.5s ease-in-out infinite',
                    '@keyframes pulse': {
                        '0%': {
                            transform: 'scale(0.95)',
                            boxShadow: '0 0 0 0 rgba(255, 68, 5, 0.7)',
                        },
                        '70%': {
                            transform: 'scale(1)',
                            boxShadow: '0 0 0 10px rgba(255, 68, 5, 0)',
                        },
                        '100%': {
                            transform: 'scale(0.95)',
                            boxShadow: '0 0 0 0 rgba(255, 68, 5, 0)',
                        },
                    },
                }}
            />
            <Typography
                sx={{
                    color: 'rgba(255, 255, 255, 0.7)',
                    fontSize: '0.875rem',
                    animation: 'fadeInOut 1.5s ease-in-out infinite',
                    '@keyframes fadeInOut': {
                        '0%': { opacity: 0.5 },
                        '50%': { opacity: 1 },
                        '100%': { opacity: 0.5 },
                    },
                }}
            >
                Loading...
            </Typography>
        </Box>
    );
};

export default LoadingSpinner;
