import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Container,
    Box,
    Typography,
    TextField,
    Button,
    Alert,
    MenuItem,
} from '@mui/material';
import { register } from '../../services/api';
import { RegisterData } from '../../types';
import Footer from '../Layout/Footer';

const Register: React.FC = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState<RegisterData>({
        email: '',
        password: '',
        confirm_password: '',
        first_name: '',
        last_name: '',
        role: 'USER',
        team: 'ENGINEERING',
        department: '',
    });
    const [error, setError] = useState<string>('');

    const teams = [
        'ENGINEERING',
        'SECURITY',
        'NETWORK',
        'DATABASE',
        'INFRASTRUCTURE',
        'PRODUCT',
        'IAM',
    ];

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setError('');

        try {
            const response = await register(formData);
            localStorage.setItem('token', response.token);
            localStorage.setItem('refresh', response.refresh);
            navigate('/dashboard');
        } catch (err) {
            setError('Registration failed. Please try again.');
        }
    };

    const inputStyle = {
        '& .MuiOutlinedInput-root': {
            backgroundColor: 'transparent',
            '& fieldset': {
                borderColor: '#FF4405',
                borderWidth: '1px',
            },
            '&:hover fieldset': {
                borderColor: '#FF4405',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#FF4405',
            },
        },
        '& input, & .MuiSelect-select': { 
            color: 'rgba(255, 255, 255, 0.87)',
            padding: '12px',
            fontSize: '14px',
        },
        '& .MuiSelect-icon': {
            color: '#FF4405',
        },
        mb: 3,
    };

    const labelStyle = {
        color: 'rgba(255, 255, 255, 0.87)',
        fontSize: '14px',
        fontWeight: 400,
        mb: 1,
    };

    return (
        <Box sx={{ 
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#111111',
            color: '#fff',
        }}>
            <Container maxWidth="xs" sx={{ flex: 1, pt: 4, pb: 4 }}>
                <Box sx={{ textAlign: 'center', mb: 4 }}>
                    <img 
                        src="/icon.png" 
                        alt="Logo" 
                        style={{ 
                            width: '64px', 
                            height: '64px',
                            marginBottom: '24px'
                        }} 
                    />
                    <Typography variant="h4" sx={{ 
                        fontSize: '32px',
                        fontWeight: 400,
                        mb: 1,
                    }}>
                        Create Account
                    </Typography>
                    <Typography sx={{ 
                        color: 'rgba(255, 255, 255, 0.6)',
                        fontSize: '14px',
                    }}>
                        Please fill in your details
                    </Typography>
                </Box>

                {error && (
                    <Alert 
                        severity="error" 
                        sx={{ 
                            mb: 3,
                            backgroundColor: 'rgba(211, 47, 47, 0.1)',
                            color: '#ff5252',
                        }}
                    >
                        {error}
                    </Alert>
                )}

                <Box component="form" onSubmit={handleSubmit}>
                    <Typography sx={labelStyle}>First Name</Typography>
                    <TextField
                        fullWidth
                        name="first_name"
                        placeholder="Enter your first name"
                        value={formData.first_name}
                        onChange={handleChange}
                        sx={inputStyle}
                    />

                    <Typography sx={labelStyle}>Last Name</Typography>
                    <TextField
                        fullWidth
                        name="last_name"
                        placeholder="Enter your last name"
                        value={formData.last_name}
                        onChange={handleChange}
                        sx={inputStyle}
                    />

                    <Typography sx={labelStyle}>Email</Typography>
                    <TextField
                        fullWidth
                        name="email"
                        placeholder="Enter your email"
                        value={formData.email}
                        onChange={handleChange}
                        sx={inputStyle}
                    />

                    <Typography sx={labelStyle}>Password</Typography>
                    <TextField
                        fullWidth
                        type="password"
                        name="password"
                        placeholder="Create a password"
                        value={formData.password}
                        onChange={handleChange}
                        sx={inputStyle}
                    />

                    <Typography sx={labelStyle}>Confirm Password</Typography>
                    <TextField
                        fullWidth
                        type="password"
                        name="confirm_password"
                        placeholder="Confirm your password"
                        value={formData.confirm_password}
                        onChange={handleChange}
                        sx={inputStyle}
                    />

                    <Typography sx={labelStyle}>Team</Typography>
                    <TextField
                        select
                        fullWidth
                        name="team"
                        value={formData.team}
                        onChange={handleChange}
                        sx={inputStyle}
                    >
                        {teams.map((team) => (
                            <MenuItem key={team} value={team}>
                                {team}
                            </MenuItem>
                        ))}
                    </TextField>

                    <Typography sx={labelStyle}>Department</Typography>
                    <TextField
                        fullWidth
                        name="department"
                        placeholder="Enter your department"
                        value={formData.department}
                        onChange={handleChange}
                        sx={inputStyle}
                    />

                    <Button
                        type="submit"
                        fullWidth
                        sx={{
                            mt: 1,
                            mb: 2,
                            py: 1.5,
                            backgroundColor: '#FF4405',
                            color: '#fff',
                            fontSize: '14px',
                            fontWeight: 500,
                            textTransform: 'uppercase',
                            '&:hover': {
                                backgroundColor: '#FF4405',
                            },
                        }}
                    >
                        CREATE ACCOUNT
                    </Button>

                    <Box sx={{ textAlign: 'center' }}>
                        <Button
                            onClick={() => navigate('/login')}
                            sx={{
                                color: '#FF4405',
                                fontSize: '14px',
                                textTransform: 'none',
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                },
                            }}
                        >
                            ALREADY HAVE AN ACCOUNT? SIGN IN
                        </Button>
                    </Box>
                </Box>
            </Container>
            <Footer />
        </Box>
    );
};

export default Register;
