import React, { useState, useEffect } from 'react';
import {
    Box,
    Paper,
    Typography,
    Grid,
    Card,
    CardContent,
} from '@mui/material';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    PieChart,
    Pie,
    Cell,
} from 'recharts';
import { getIncidentReport, getIncidentStatistics } from '../../services/api';
import LoadingSpinner from '../Layout/LoadingSpinner';

const COLORS = ['#FF4405', '#4CAF50', '#2196F3', '#FFC107', '#9C27B0', '#FF9800'];

const Reports: React.FC = () => {
    const [loading, setLoading] = useState(true);
    const [reportData, setReportData] = useState<any>(null);
    const [statistics, setStatistics] = useState<any>(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const [reportData, statsData] = await Promise.all([
                getIncidentReport(),
                getIncidentStatistics()
            ]);
            setReportData(reportData);
            setStatistics(statsData);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const formatDistributionData = (data: Record<string, number>) => {
        return Object.entries(data).map(([name, value]) => ({
            name,
            value
        }));
    };

    const formatTeamPerformanceData = (data: Record<string, { total_incidents: number, resolved_incidents: number }>) => {
        return Object.entries(data)
            .filter(([_, stats]) => stats.total_incidents > 0) // Only show teams with incidents
            .map(([name, stats]) => ({
                name,
                total: stats.total_incidents,
                resolved: stats.resolved_incidents
            }));
    };

    if (loading || !reportData || !statistics) {
        return <LoadingSpinner />;
    }

    const typeData = formatDistributionData(reportData.distributions.by_type);
    const priorityData = formatDistributionData(reportData.distributions.by_priority);
    const userImpactData = formatDistributionData(reportData.distributions.by_user_impact);
    const teamPerformanceData = formatTeamPerformanceData(reportData.team_performance);

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4" sx={{ color: '#fff', mb: 4 }}>
                Reports & Analytics
            </Typography>

            <Grid container spacing={3}>
                {/* Summary Cards */}
                <Grid item xs={12} sm={4}>
                    <Card sx={{ 
                        backgroundColor: 'rgba(255, 255, 255, 0.05)',
                        backdropFilter: 'blur(10px)',
                        border: '1px solid rgba(255, 255, 255, 0.1)',
                    }}>
                        <CardContent>
                            <Typography sx={{ color: 'rgba(255, 255, 255, 0.6)' }} gutterBottom>
                                Total Incidents
                            </Typography>
                            <Typography variant="h4" sx={{ color: '#fff' }}>
                                {statistics.total_incidents}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Card sx={{ 
                        backgroundColor: 'rgba(255, 255, 255, 0.05)',
                        backdropFilter: 'blur(10px)',
                        border: '1px solid rgba(255, 255, 255, 0.1)',
                    }}>
                        <CardContent>
                            <Typography sx={{ color: 'rgba(255, 255, 255, 0.6)' }} gutterBottom>
                                Resolved Incidents
                            </Typography>
                            <Typography variant="h4" sx={{ color: '#4CAF50' }}>
                                {statistics.by_status?.RESOLVED || 0}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Card sx={{ 
                        backgroundColor: 'rgba(255, 255, 255, 0.05)',
                        backdropFilter: 'blur(10px)',
                        border: '1px solid rgba(255, 255, 255, 0.1)',
                    }}>
                        <CardContent>
                            <Typography sx={{ color: 'rgba(255, 255, 255, 0.6)' }} gutterBottom>
                                Resolution Rate
                            </Typography>
                            <Typography variant="h4" sx={{ color: '#2196F3' }}>
                                {statistics.total_incidents
                                    ? Math.round(
                                          ((statistics.total_incidents - statistics.open_incidents) /
                                              statistics.total_incidents) *
                                              100
                                      )
                                    : 0}%
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Rest of the charts remain the same */}
                {/* Distribution Charts */}
                <Grid item xs={12} md={4}>
                    <Paper sx={{ 
                        p: 3,
                        height: '400px',
                        backgroundColor: 'rgba(255, 255, 255, 0.05)',
                        backdropFilter: 'blur(10px)',
                        border: '1px solid rgba(255, 255, 255, 0.1)',
                    }}>
                        <Typography variant="h6" gutterBottom sx={{ color: '#fff' }}>
                            Incidents by Type
                        </Typography>
                        <Box sx={{ width: '100%', height: 'calc(100% - 40px)' }}>
                            <ResponsiveContainer>
                                <PieChart>
                                    <Pie
                                        data={typeData}
                                        dataKey="value"
                                        nameKey="name"
                                        cx="50%"
                                        cy="50%"
                                        outerRadius={120}
                                        label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                                    >
                                        {typeData.map((_, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                    <Tooltip 
                                        contentStyle={{ 
                                            backgroundColor: '#1A1A1A',
                                            border: '1px solid rgba(255, 255, 255, 0.1)',
                                        }}
                                        labelStyle={{
                                            color: '#FFFFFF',
                                        }}
                                        itemStyle={{
                                            color: '#FFFFFF',
                                        }}
                                    />
                                    <Legend />
                                </PieChart>
                            </ResponsiveContainer>
                        </Box>
                    </Paper>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Paper sx={{ 
                        p: 3,
                        height: '400px',
                        backgroundColor: 'rgba(255, 255, 255, 0.05)',
                        backdropFilter: 'blur(10px)',
                        border: '1px solid rgba(255, 255, 255, 0.1)',
                    }}>
                        <Typography variant="h6" gutterBottom sx={{ color: '#fff' }}>
                            Priority Distribution
                        </Typography>
                        <Box sx={{ width: '100%', height: 'calc(100% - 40px)' }}>
                            <ResponsiveContainer>
                                <PieChart>
                                    <Pie
                                        data={priorityData}
                                        dataKey="value"
                                        nameKey="name"
                                        cx="50%"
                                        cy="50%"
                                        outerRadius={120}
                                        label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                                    >
                                        {priorityData.map((_, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                    <Tooltip 
                                        contentStyle={{ 
                                            backgroundColor: '#1A1A1A',
                                            border: '1px solid rgba(255, 255, 255, 0.1)',
                                        }}
                                        labelStyle={{
                                            color: '#FFFFFF',
                                        }}
                                        itemStyle={{
                                            color: '#FFFFFF',
                                        }}
                                    />
                                    <Legend />
                                </PieChart>
                            </ResponsiveContainer>
                        </Box>
                    </Paper>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Paper sx={{ 
                        p: 3,
                        height: '400px',
                        backgroundColor: 'rgba(255, 255, 255, 0.05)',
                        backdropFilter: 'blur(10px)',
                        border: '1px solid rgba(255, 255, 255, 0.1)',
                    }}>
                        <Typography variant="h6" gutterBottom sx={{ color: '#fff' }}>
                            User Impact Distribution
                        </Typography>
                        <Box sx={{ width: '100%', height: 'calc(100% - 40px)' }}>
                            <ResponsiveContainer>
                                <PieChart>
                                    <Pie
                                        data={userImpactData}
                                        dataKey="value"
                                        nameKey="name"
                                        cx="50%"
                                        cy="50%"
                                        outerRadius={120}
                                        label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                                    >
                                        {userImpactData.map((_, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                    <Tooltip 
                                        contentStyle={{ 
                                            backgroundColor: '#1A1A1A',
                                            border: '1px solid rgba(255, 255, 255, 0.1)',
                                        }}
                                        labelStyle={{
                                            color: '#FFFFFF',
                                        }}
                                        itemStyle={{
                                            color: '#FFFFFF',
                                        }}
                                    />
                                    <Legend />
                                </PieChart>
                            </ResponsiveContainer>
                        </Box>
                    </Paper>
                </Grid>

                {/* Team Performance Chart */}
                <Grid item xs={12}>
                    <Paper sx={{ 
                        p: 3,
                        height: '400px',
                        backgroundColor: 'rgba(255, 255, 255, 0.05)',
                        backdropFilter: 'blur(10px)',
                        border: '1px solid rgba(255, 255, 255, 0.1)',
                    }}>
                        <Typography variant="h6" gutterBottom sx={{ color: '#fff' }}>
                            Team Performance
                        </Typography>
                        <Box sx={{ width: '100%', height: 'calc(100% - 40px)' }}>
                            <ResponsiveContainer>
                                <BarChart data={teamPerformanceData}>
                                    <CartesianGrid strokeDasharray="3 3" stroke="rgba(255, 255, 255, 0.1)" />
                                    <XAxis 
                                        dataKey="name" 
                                        stroke="#fff"
                                    />
                                    <YAxis 
                                        stroke="#fff"
                                    />
                                    <Tooltip 
                                        contentStyle={{ 
                                            backgroundColor: '#1A1A1A',
                                            border: '1px solid rgba(255, 255, 255, 0.1)',
                                        }}
                                        labelStyle={{
                                            color: '#FFFFFF',
                                        }}
                                        itemStyle={{
                                            color: '#FFFFFF',
                                        }}
                                    />
                                    <Legend />
                                    <Bar name="Total Incidents" dataKey="total" fill="#FF4405" />
                                    <Bar name="Resolved Incidents" dataKey="resolved" fill="#4CAF50" />
                                </BarChart>
                            </ResponsiveContainer>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Reports;
