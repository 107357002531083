import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Chip,
    IconButton,
    Avatar,
    CircularProgress,
    Link,
    Stack,
} from '@mui/material';
import {
    Edit as EditIcon,
    Add as AddIcon,
    NavigateNext as NavigateNextIcon,
    NavigateBefore as NavigateBeforeIcon,
    AttachFile as AttachFileIcon,
    Label as LabelIcon,
} from '@mui/icons-material';
import { getIncidents } from '../../services/api';
import { Incident } from '../../types';
import { UserContext } from '../../App';

const IncidentList: React.FC = () => {
    const navigate = useNavigate();
    const { currentUser } = useContext(UserContext);
    const [incidents, setIncidents] = useState<Incident[]>([]);
    const [loading, setLoading] = useState(true);
    const [nextPage, setNextPage] = useState<string | null>(null);
    const [previousPage, setPreviousPage] = useState<string | null>(null);

    useEffect(() => {
        fetchIncidents('/incidents/');
    }, []);

    const extractPathFromUrl = (url: string) => {
        const parsedUrl = new URL(url);
        const path = parsedUrl.pathname.replace('/api', '') + parsedUrl.search;
        return path;
    };

    const fetchIncidents = async (path: string) => {
        try {
            const data = await getIncidents(path);
            setIncidents(data.results);
            setNextPage(data.next ? extractPathFromUrl(data.next) : null);
            setPreviousPage(data.previous ? extractPathFromUrl(data.previous) : null);
        } catch (error) {
            console.error('Error fetching incidents:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleNextPage = () => {
        if (nextPage) {
            fetchIncidents(nextPage);
        }
    };

    const handlePreviousPage = () => {
        if (previousPage) {
            fetchIncidents(previousPage);
        }
    };

    const getStatusColor = (status: string) => {
        switch (status) {
            case 'CLOSED':
            case 'RESOLVED':
                return '#00AB55';
            case 'MITIGATED':
            case 'DIAGNOSED':
                return '#1890FF';
            case 'ASSIGNED':
            case 'ACKNOWLEDGED':
                return '#FFC107';
            default:
                return '#FF4842';
        }
    };

    const getPriorityColor = (priority: string) => {
        switch (priority) {
            case 'CRITICAL':
                return '#FF4842';
            case 'HIGH':
                return '#FFC107';
            case 'MEDIUM':
                return '#1890FF';
            default:
                return '#00AB55';
        }
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress sx={{ color: '#FF4405' }} />
            </Box>
        );
    }

    return (
        <Box sx={{ p: 3 }}>
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center', 
                mb: 4 
            }}>
                <Typography variant="h4" sx={{ color: '#fff' }}>
                    Incidents
                </Typography>
                {currentUser?.role !== 'GUEST' && (
                    <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => navigate('/incidents/new')}
                        sx={{
                            bgcolor: '#FF4405',
                            '&:hover': {
                                bgcolor: '#ff6b2c',
                            },
                        }}
                    >
                        Create Incident
                    </Button>
                )}
            </Box>

            <TableContainer 
                component={Paper}
                sx={{
                    backgroundColor: 'rgba(255, 255, 255, 0.05)',
                    backdropFilter: 'blur(10px)',
                    border: '1px solid rgba(255, 255, 255, 0.1)',
                    mb: 2
                }}
            >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ color: 'rgba(255, 255, 255, 0.6)', borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>Title</TableCell>
                            <TableCell sx={{ color: 'rgba(255, 255, 255, 0.6)', borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>Priority</TableCell>
                            <TableCell sx={{ color: 'rgba(255, 255, 255, 0.6)', borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>Status</TableCell>
                            <TableCell sx={{ color: 'rgba(255, 255, 255, 0.6)', borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>Tags & Attachments</TableCell>
                            <TableCell sx={{ color: 'rgba(255, 255, 255, 0.6)', borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>Assigned To</TableCell>
                            <TableCell sx={{ color: 'rgba(255, 255, 255, 0.6)', borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>Created At</TableCell>
                            {currentUser?.role !== 'GUEST' && (
                                <TableCell sx={{ color: 'rgba(255, 255, 255, 0.6)', borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>Actions</TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {incidents.map((incident) => (
                            <TableRow key={incident.id}>
                                <TableCell 
                                    sx={{ 
                                        color: '#fff',
                                        borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
                                    }}
                                >
                                    {incident.title}
                                </TableCell>
                                <TableCell 
                                    sx={{ 
                                        borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
                                    }}
                                >
                                    <Chip
                                        label={incident.priority}
                                        size="small"
                                        sx={{
                                            bgcolor: getPriorityColor(incident.priority),
                                            color: '#fff',
                                        }}
                                    />
                                </TableCell>
                                <TableCell 
                                    sx={{ 
                                        borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
                                    }}
                                >
                                    <Chip
                                        label={incident.status}
                                        size="small"
                                        sx={{
                                            bgcolor: getStatusColor(incident.status),
                                            color: '#fff',
                                        }}
                                    />
                                </TableCell>
                                <TableCell 
                                    sx={{ 
                                        borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
                                    }}
                                >
                                    <Stack spacing={1}>
                                        {/* Tags */}
                                        {incident.tags && incident.tags.length > 0 && (
                                            <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
                                                {incident.tags.map((tag, index) => (
                                                    <Chip
                                                        key={index}
                                                        label={tag}
                                                        size="small"
                                                        icon={<LabelIcon />}
                                                        sx={{
                                                            bgcolor: 'rgba(255, 68, 5, 0.1)',
                                                            color: '#fff',
                                                            borderColor: '#FF4405',
                                                        }}
                                                    />
                                                ))}
                                            </Box>
                                        )}
                                        {/* Attachments */}
                                        {incident.attachments && incident.attachments.length > 0 && (
                                            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                                                {incident.attachments.map((attachment, index) => (
                                                    <Link
                                                        key={index}
                                                        href={attachment.file}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: 0.5,
                                                            color: '#FF4405',
                                                            textDecoration: 'none',
                                                            '&:hover': {
                                                                textDecoration: 'underline',
                                                            },
                                                        }}
                                                    >
                                                        <AttachFileIcon sx={{ fontSize: 16 }} />
                                                        {attachment.file_name}
                                                    </Link>
                                                ))}
                                            </Box>
                                        )}
                                    </Stack>
                                </TableCell>
                                <TableCell 
                                    sx={{ 
                                        borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
                                    }}
                                >
                                    {incident.assigned_to ? (
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            <Avatar
                                                src={incident.assigned_to.profile_picture}
                                                sx={{ 
                                                    width: 24, 
                                                    height: 24,
                                                    border: '1px solid #FF4405'
                                                }}
                                            >
                                                {incident.assigned_to.first_name[0]}
                                            </Avatar>
                                            <Typography sx={{ color: '#fff' }}>
                                                {`${incident.assigned_to.first_name} ${incident.assigned_to.last_name}`}
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <Typography sx={{ color: 'rgba(255, 255, 255, 0.6)' }}>
                                            Unassigned
                                        </Typography>
                                    )}
                                </TableCell>
                                <TableCell 
                                    sx={{ 
                                        color: 'rgba(255, 255, 255, 0.6)',
                                        borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
                                    }}
                                >
                                    {new Date(incident.created_at).toLocaleString()}
                                </TableCell>
                                {currentUser?.role !== 'GUEST' && (
                                    <TableCell 
                                        sx={{ 
                                            borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
                                        }}
                                    >
                                        <IconButton
                                            onClick={() => navigate(`/incidents/${incident.id}/edit`)}
                                            sx={{ 
                                                color: '#FF4405',
                                                '&:hover': {
                                                    bgcolor: 'rgba(255, 68, 5, 0.1)',
                                                },
                                            }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Navigation Controls */}
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'center',
                gap: 2,
                mt: 2
            }}>
                <Button
                    onClick={handlePreviousPage}
                    disabled={!previousPage}
                    startIcon={<NavigateBeforeIcon />}
                    sx={{
                        color: '#fff',
                        borderColor: 'rgba(255, 255, 255, 0.23)',
                        '&:disabled': {
                            color: 'rgba(255, 255, 255, 0.3)',
                        },
                        '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.05)',
                        },
                    }}
                    variant="outlined"
                >
                    Previous
                </Button>
                <Button
                    onClick={handleNextPage}
                    disabled={!nextPage}
                    endIcon={<NavigateNextIcon />}
                    sx={{
                        color: '#fff',
                        borderColor: 'rgba(255, 255, 255, 0.23)',
                        '&:disabled': {
                            color: 'rgba(255, 255, 255, 0.3)',
                        },
                        '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.05)',
                        },
                    }}
                    variant="outlined"
                >
                    Next
                </Button>
            </Box>
        </Box>
    );
};

export default IncidentList;
